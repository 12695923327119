<template>
  <div>
    <Text :size="18" :weight="600">Meal point history</Text>
    <!-- <hr /> -->
    <!-- <div class="py-3 flex-col gap-3 flex">
      <Text :size="14" :weight="600">
        Total MealBot points earned :
        {{ totalmealPointsEarned || 0 }}
      </Text>
      <Text :size="14" :weight="600">
        Total MealBot points redeemed :
        {{ totalMealBotPointRedeemed || 0 }}
      </Text>
      <Text :size="14" :weight="600">
        Total Mealbot point expired :
        {{ totalMealBotPointExpired || 0 }}
      </Text>
    </div> -->

    <div class="responsize-table w-100">
      <table class="meal-point-history-table">
        <thead>
          <tr class="w-full justify-between border-b">
            <th>S/N</th>
            <th>Date</th>
            <th>Type</th>
            <th>Points</th>
            <th>Expired</th>
            <th>Order</th>
            <!-- <th>Not registered</th> -->
            <th>Balance</th>
          </tr>
        </thead>
        <tbody class="w-100">
          <tr
            class="w-full border my-2"
            v-for="(item, i) in paginatedmealPoints"
            :key="i + 1 + meaPointPerPage * (currentPage - 1)"
          >
            <td>
              {{ i + 1 + meaPointPerPage * (currentPage - 1) }}
            </td>
            <td>
              {{
                dayjs(item.created.replaceAll('"', "")).format("MMM DD YYYY.")
              }}
            </td>
            <td>{{ item.pointType }}</td>
            <td>{{ item.point }}</td>
            <td>{{ item.expired ? "Yes" : "No" }}</td>

            <td><OrderID :id="item.orderId" /></td>

            <!-- <td>{{ item.notRegistered ? "Yes" : "No" }}</td> -->
            <td>
              {{
                getBalanceAtHistory(
                  item,
                  i + 1 + meaPointPerPage * (currentPage - 1)
                ).total
              }}
              <!-- {{
                getBalanceAtHistory(
                  item,
                  i + 1 + meaPointPerPage * (currentPage - 1)
                ).prev
              }} -->
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </div>
</template>
  
  <script setup>
import Pagination from "@/components/Pagination.vue";
import { sum } from "@/util";
import dayjs from "dayjs";
import { runExpiry } from "./runExpiry";
import { computed, ref, watchEffect } from "vue";
import Text from "@/components/Text.vue";
import OrderID from "@/components/OrderID.vue";

const props = defineProps({
  customer: Object,
  orders: Array,
});
const customer = ref({});
const totalMealBotPointRedeemed = ref(0);
const mealPointsHistory = ref([]);
const totalmealPointsEarned = ref(0);
const totalMealBotPointExpired = ref(0);

watchEffect(() => {
  customer.value = props.customer;
});

const getCustomers = async () => {
  // ------------
  if (customer.value?.mealPoint?.history) {
    mealPointsHistory.value = customer.value?.mealPoint?.history?.toSorted(
      (a, b) =>
        b?.created
          ?.replaceAll('"', "")
          ?.localeCompare(a?.created?.replaceAll('"', ""))
    );

    const ordersWithPointRedeemed = props.orders
      // ?.filter((order) => !order?.status?.Cancelled?.state)
      .filter((order) => {
        const items = order?.items;
        const hasPoint = items?.some((item) => item?.point > 0);
        return hasPoint;
      });

    /*    const missingPointOrders = ordersWithPointRedeemed?.filter(
      (order) =>
        !mealPointsHistory.value?.find(
          (history) =>
            history?.pointType === "subtract" && order?.id === history?.orderId
        )
    );
    const missingHistory = missingPointOrders.map((order) => ({
      orderId: order?.id,
      pointType: "subtract",
      point: sum(order?.items?.map((item) => parseInt(item?.point))),
      created: order?.date,
      notRegistered: true,
    }));
    mealPointsHistory.value = mealPointsHistory.value.concat(missingHistory);
*/
  }
  // ------------
};
watchEffect(async () => {
  getCustomers();
});

const getBalanceAtHistory = (details, index) => {
  const pointType = details?.pointType;
  const start = 0;
  const end = mealPointsHistory.value.length - index + 1;
  let historyRange = mealPointsHistory.value.toReversed();
  historyRange = historyRange.slice(start, end);
  // console.log(start, end, historyRange[end - 1]);

  const calc = (arr) => sum(arr?.map((a) => a?.point));

  const adds = calc(
    historyRange?.filter((history) => history?.pointType === "add")
  );
  const subtracts = calc(
    historyRange?.filter(
      (history) =>
        history?.pointType === "subtract" && !history?.expired && history?.point
    )
  );
  const expiredPoints = calc(
    historyRange?.filter(
      (history) => history?.pointType === "add" && history?.expired
    )
  );
  // if (details?.expired) return "---";
  const type = history?.pointType === "add" ? "add" : "subtract";
  return {
    total: adds - (subtracts + expiredPoints),
    len: historyRange.length,
    prev: historyRange[end - 1]?.point,
    type,
  };
};

// Pagination
const meaPointPerPage = 10;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(mealPointsHistory.value.length / meaPointPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedmealPoints = computed(() => {
  const startIndex = (currentPage.value - 1) * meaPointPerPage;
  const endIndex = startIndex + meaPointPerPage;
  return mealPointsHistory.value.slice(startIndex, endIndex);
});
</script>
  
  <style lang="scss" scoped>
.meal-point-history-table {
  border-collapse: collapse;
  border-spacing: 0 15px;
  width: 100%;

  tr {
    row-gap: 12px;
    &:nth-child(even) {
      background: #efefef;
    }
  }
  th,
  td {
    padding: 5px;
  }
}
</style>