<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <layout :hasTopNav="false" :hasSearch="false">
    <div class="mainContent">
      <section class="flex justify-between">
        <Text size="27" weight="600">Dashboard</Text>

        <div class="flex gap-2">
          <time-drop-down :getDate="(e) => (timeFrame = e)" />
          <ue-button @click="downloadOrderStats">Download </ue-button>
        </div>
      </section>
      <section class="metricsBar w-full">
        <div v-for="metric in metricList" :key="metric.title" class="metric">
          <div class="metric-info">
            <Text :size="32" :weight="600">{{ metric.totalNumber }}</Text>
            <div class="flex gap-2 items-center">
              <Text :size="15" :weight="600">{{ metric.title }} </Text
              ><Text :weight="600" v-if="metric?.extra" size="9px">{{
                metric?.extra
              }}</Text>
            </div>

            <!-- <p>{{ metric.completedNumber }} Completed</p> -->
          </div>
          <div class="metric-icon">
            <i :class="metric.iconClass"></i>
            <div class="w-[50px] h-[50px] border rounded-full gradienticon">
              <span class="material-symbols-outlined text-[white] sidebar-icon">
                {{ metric.icon }}
              </span>
            </div>
          </div>
        </div>
      </section>

      <!-- Order Summary and Revenue -->
      <section class="sum_rev">
        <div><average-kitchen-assembler-time /></div>
        <div><average-delivery-time /></div>
      </section>

      <!-- Order Summary and Revenue -->
      <section class="sum_rev">
        <div><OrderSummary /></div>
        <div><revenue /></div>
      </section>

      <!-- Customer map and Top Selling items -->
      <section class="cus_top">
        <div class="graph1"><cutomer-map /></div>
        <div class="top_selling"><top-selling /></div>
      </section>

      <!-- Top restaurants -->
      <section class="cus_top">
        <!-- <div class="graph1"><cutomer-map /></div> -->
        <div class="top_selling"><top-restaurants /></div>
        <div class="top_selling"><CancelledMeals /></div>
      </section>

      <meal-summary />
      <!-- Recent orders -->
      <section class="recent_orders">
        <recent-orders />
      </section>
      <!-- <section class="recent_orders">
        <orders-in-time-frame />
      </section> -->
    </div>
  </layout>
</template>
      
    <script>
import { ref, watchEffect } from "vue";
import getUser from "@/composables/getUser";
import SideBar from "@/components/SideBar.vue";

import AdminUser from "@/components/AdminUser.vue";
import Text from "@/components/Text.vue";
import Layout from "@/partials/Layout/layout.vue";
import OrderSummary from "./OrderSummary.vue";
import Revenue from "./Revenue.vue";
import CutomerMap from "./OrderAnalytics.vue";
import TopSelling from "./TopSelling.vue";
// import RecentOrdersVue
import RecentOrders from "./RecentOrders.vue";
import { store } from "@/views/store/store";
import { formatPrice, getOrdersByTimeFrame, sum } from "@/util";
import dayjs from "dayjs";
import AverageDeliveryTime from "../../Logistics/Dashboard/AverageDeliveryTime.vue";
import AverageKitchenAssemblerTime from "./AverageKitchenAssemblerTime.vue";
import TimeDropDown from "./TimeDropDown.vue";
import MealSummary from "./MealSummary.vue";
import OrdersInTimeFrame from "./OrdersInTimeFrame.vue";
import { json2csv } from "json-2-csv";
import { export2csv } from "./exportcsv";
import UeButton from "@/components/UeButton.vue";
import TopRestaurants from "./TopRestaurants.vue";
import {
  ordersByCustomer,
  ordersByItem,
  ordersByRestaurant,
} from "@/util/queries";
import CancelledMeals from "./CancelledMeals.vue";
import { canceledItems } from "@/util/cancelledItems";
export default {
  components: {
    SideBar,
    AdminUser,
    Layout,
    Text,
    OrderSummary,
    Revenue,
    TopSelling,
    CutomerMap,
    RecentOrders,
    AverageDeliveryTime,
    AverageKitchenAssemblerTime,
    TimeDropDown,
    MealSummary,
    OrdersInTimeFrame,
    UeButton,
    TopRestaurants,
    CancelledMeals,
  },

  setup() {
    //to get user's display name for icon
    const { user } = getUser();
    const timeFrame = ref();
    // const today =
    const metricList = ref([]);
    const calculateTimeDifference = (start, end, compare, ID) => {
      const toDate = (date) => dayjs(date?.replaceAll('"', ""));
      const res = Math.abs(toDate(start).diff(toDate(end), "minutes"));
      return res <= compare;
    };

    const quantiyWithCancelled = (item) => {
      return item?.quantity - (item?.cancelled || item?.canceled || 0);
    };

    const countMeals = (orders) => {
      let count = orders
        .map((order) => {
          return order.items?.map((item) => {
            let tot = quantiyWithCancelled(item);
            if (item?.addons) {
              tot = tot + item?.addons?.length * quantiyWithCancelled(item);
            }
            if (item?.sides) {
              tot = tot + item?.sides?.length * quantiyWithCancelled(item);
            }
            if (item?.customize) {
              tot = tot + item?.customize?.length * quantiyWithCancelled(item);
            }
            return tot;
          });
        })
        .flat(1)
        .reduce((a, b) => a + b);

      return count;
    };

    watchEffect(async () => {
      let todaysOrders = await getOrdersByTimeFrame(
        timeFrame.value?.start,
        timeFrame.value?.end
      );

      // console.log(todaysOrders.map(order => `${order?.id} = ${dayjs(order?.date?.replaceAll('"','')).format('MMM, DD')}`))
      // console.log('first-----',ordersByCustomer({orders:todaysOrders,email:"emmanuelchidike05@gmail.com "}))
      let todayMeals = 0,
        todaysRevenue = 0,
        totalCancelled = 0,
        kitchen_on_time = 0,
        kitchen_on_time_percent = "0%",
        logistics_on_time = 0,
        logistics_on_time_percent = 0,
        logistics_on_time_extended = 0,
        logistics_on_time_extended_percent = 0,
        delivery_on_time_regular = 0,
        delivery_on_time_regular_percent = 0,
        delivery_on_time_extended = 0,
        delivery_on_time_extended_percent = 0,
        aov = 0,
        top_restaurant = { restaurant: "", qty: 0 };

      if (todaysOrders.length) {
        console.log(
          "=",
          todaysOrders
            ?.filter((order) =>
              Object.values(order?.timeline || {}).some((item) =>
                item.message?.includes("switched")
              )
            )
            .map((order) => order.id)
            .join(", ")
        );
        todayMeals = countMeals(todaysOrders);

        // ["Pounded Yam", "milkshake", "hotdog"];
        const ordersByItem_ = [
          "Pounded Yam",
          "milkshake",
          "Classic Hot Dog",
          "Coney Island Hot Dog",
        ].map((item) => ordersByItem(todaysOrders, item));
        // console.log(
        //   "ORDERS BY ITEM",
        //   ordersByItem_.map((orders) =>
        //     orders?.map((order) => order?.id).join(", ")
        //   )
        // );

        const ordersByRestaurant_ = ordersByRestaurant(
          todaysOrders,
          "Crêpe & Co Café"
        );
        // console.log(
        //   "RESTAURANT ORDERS",
        //   ordersByRestaurant_?.map((order) => order?.id).join(", ")
        // );

        todaysRevenue = todaysOrders
          .map((x) => x.total_price)
          .reduce((a, b) => a + b);
        totalCancelled = canceledItems(todaysOrders).total;
        // todaysOrders
        //   ?.map((x) => x.items.map((x) => x.cancelled || x?.canceled))
        //   .flat(1).filter(num => num)
        //   .reduce((a, b) => a + b);

        const preparedOrders = todaysOrders?.filter(
          (order) =>
            order?.status?.Assembled?.state && !order?.status?.Cancelled?.state
        );

        const DeliveredOrders = todaysOrders?.filter(
          (order) =>
            order?.locationType === "Delivery" &&
            order?.status?.Delivered?.state &&
            !order?.status?.Cancelled?.state
        );
        const DeliveredOrdersRegular = DeliveredOrders?.filter(
          (order) => order?.delivery_range !== "extended"
        );
        const DeliveredOrdersExtended = DeliveredOrders?.filter(
          (order) => order?.delivery_range === "extended"
        );

        kitchen_on_time = preparedOrders?.filter((order) =>
          calculateTimeDifference(
            order?.status?.Confirmed?.time,
            order?.status?.Assembled?.time,
            30,
            order?.id
          )
        )?.length;

        kitchen_on_time_percent = `${Math.round(
          (kitchen_on_time / preparedOrders.length) * 100
        )}%`;
        // console.log(DeliveredOrders,'----');
        logistics_on_time = DeliveredOrdersRegular?.filter((order) =>
          calculateTimeDifference(
            order?.status?.Dispatched?.time,
            order?.status?.Arrived?.time,
            30,
            order?.id
          )
        )?.length;
        logistics_on_time_percent = `${Math.round(
          (logistics_on_time / DeliveredOrdersRegular.length) * 100
        )}% (${logistics_on_time})`;

        logistics_on_time_extended = DeliveredOrdersExtended?.filter((order) =>
          calculateTimeDifference(
            order?.status?.Dispatched?.time,
            order?.status?.Arrived?.time,
            60,
            order?.id
          )
        )?.length;
        logistics_on_time_extended_percent =`${ Math.round(
          (logistics_on_time_extended / (DeliveredOrdersExtended.length || 1)) *
            100
        )}% (${logistics_on_time_extended})`;
        delivery_on_time_regular = DeliveredOrdersRegular?.filter((order) =>
          calculateTimeDifference(
            order?.status?.Confirmed?.time,
            order?.status?.Delivered?.time,
            60,
            order?.id
          )
        )?.length;
        delivery_on_time_regular_percent = `${Math.round(
          (delivery_on_time_regular /( DeliveredOrdersRegular.length || 1)) * 100
        )}% (${delivery_on_time_regular})`;

        delivery_on_time_extended = DeliveredOrdersExtended?.filter((order) =>
          calculateTimeDifference(
            order?.status?.Confirmed?.time,
            order?.status?.Delivered?.time,
            90,
            order?.id
          )
        )?.length;
        delivery_on_time_extended_percent = `${Math.round(
          (delivery_on_time_extended / (DeliveredOrdersExtended.length || 1)) * 100
        )}% (${delivery_on_time_extended})`;

        aov =
          parseInt(
            (todaysRevenue / todaysOrders.length).toString().split(".")[0]
          ) || 0;

        const items = todaysOrders
          ?.flatMap((order) => order?.items)
          ?.map((item) => ({
            restaurant: item?.resturant,
            qty: item?.quantity - (item?.cancelled || item?.canceled || 0),
          }));
        const restaurantSet = new Set();
        let resCount = {};
        items.forEach((item) => {
          const res = item?.restaurant;
          const qty = item?.qty;
          if (restaurantSet.has(res)) {
            resCount[res] = resCount[res] + qty;
          } else {
            restaurantSet.add(res);
            resCount[res] = qty || 0;
          }
        });
        for (const key in resCount) {
          const element = resCount[key];
          if (element >= top_restaurant.qty) {
            top_restaurant.qty = element;
            top_restaurant.restaurant = key;
          }
        }
      }

      const name = timeFrame.value?.time?.replace("-", " ") || "";
      const display_name =
        name[0]?.toUpperCase() + name?.substring(1, name.length);
      metricList.value = [
        {
          title: `${display_name}'s Meals`,
          totalNumber: todayMeals,
          icon: "Fastfood",
        },
        {
          title: `${display_name}'s Revenue`,
          totalNumber: formatPrice(todaysRevenue),
          icon: "payment",
        },
        {
          title: `${display_name}'s Orders`,
          totalNumber: todaysOrders.length,
          icon: "order_approve ",
        },
        {
          title: "Cancelled Meals",
          totalNumber: totalCancelled,
          icon: "cancel",
        },
        // {
        //   title: "Kitchen/Assembler On time",
        //   totalNumber: kitchen_on_time,
        //   icon: "skillet_cooktop",
        //   extra: "30mins",
        // },
        {
          title: "Kitchen/Assembler On time ", /*(%)*/
          totalNumber: kitchen_on_time_percent,
          icon: "skillet_cooktop",
          extra: "30mins",
        },
        // {
        //   title: "Logistics On time [Regular]",
        //   totalNumber: logistics_on_time,
        //   icon: "electric_moped",
        //   extra: "30mins",
        // },
        {
          title: "Logistics On time [Regular] ", /*(%)*/
          totalNumber: logistics_on_time_percent,
          icon: "electric_moped",
          extra: "30mins",
        },
        // {
        //   title: "Logistics On time [Extended]",
        //   totalNumber: logistics_on_time_extended,
        //   icon: "electric_moped",
        //   extra: "60mins",
        // },
        {
          title: "Logistics On time [Extended] ", /*(%)*/
          totalNumber: logistics_on_time_extended_percent,
          icon: "electric_moped",
          extra: "60mins",
        },
        // {
        //   title: "Delivery on time  [Regular]",
        //   totalNumber: delivery_on_time_regular,
        //   icon: "electric_moped",
        //   extra: "60mins",
        // },
        {
          title: "Delivery on time  [Regular] ", /*(%)*/
          totalNumber: delivery_on_time_regular_percent,
          icon: "electric_moped",
          extra: "60mins",
        },
        // {
        //   title: "Delivery on time  [Extended]",
        //   totalNumber: delivery_on_time_extended,
        //   icon: "electric_moped",
        //   extra: "90mins",
        // },
        {
          title: "Delivery on time  [Extended] ", /*(%)*/
          totalNumber: delivery_on_time_extended_percent,
          icon: "electric_moped",
          extra: "90mins",
        },
        {
          title: "Average Order Value ",
          totalNumber: formatPrice(aov),
          icon: "electric_moped",
        },
        {
          title: "Top Restaurant",
          totalNumber: top_restaurant?.restaurant,
          icon: "electric_moped",
        },
      ];
    });

    const downloadOrderStats = async () => {
      let todaysOrders = await getOrdersByTimeFrame(
        timeFrame.value?.start,
        timeFrame.value?.end
      );

      let stats = [];
      const start = dayjs(timeFrame.value?.start);
      const end = dayjs(timeFrame.value?.end);
      const days = end.diff(start, "days");
      // console.log(days);
      if (days) {
        let loopday = start;
        for (let index = 0; index < days + 1; index++) {
          // console.log(loopday.format("YYYY MMM DD "), index);

          const date = loopday.format("YYYY-MM-DD");
          const orders = todaysOrders.filter(
            (order) =>
              dayjs(order?.date?.replaceAll('"', "")).format("YYYY-MM-DD") ===
              date
          );
          const orderIDs = orders.map((order) => order?.id).join(" ");
          stats.push({
            date,
            length: orders.length,
            orderIDs,
            length: orders.length,
          });
          loopday = loopday.add(1, "day");
        }
      }

      const csv = json2csv(stats);
      export2csv(csv, "export.csv", "text/csv;charset=utf-8;");
    };

    return {
      metricList,
      formatPrice,
      timeFrame,
      downloadOrderStats,
    };
  },
};
</script>
      
      <style scoped lang="scss">
.mainContent {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 40px;
  @media screen and (max-width: 992px) {
    padding: 15px;
  }
  .metricsBar {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // gridr
    grid-gap: 2%;
    row-gap: 2%;
    margin: auto;
    justify-content: space-evenly;
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0.5%;
      row-gap: 0.5%;
      // padding: 15px;
    }
    // column-gap: 12px;
  }
  .metric {
    display: flex;
    justify-content: space-between;
    color: black;
    padding: 10px;
    border-radius: 14px;
    background: #fff;
    align-items: center;
    margin: 5px 0;
  }
  .metric-info {
    text-align: left;
    padding: 0 12px;
  }
  .sum_rev {
    display: flex;
    // gap: 32px;
    justify-content: space-between;
    width: 100%;
    & > div {
      width: calc(50% - 16px);
      height: 453px;
      flex-shrink: 0;
      border-radius: 16px;
      background: #fff;
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }
  .cus_top {
    display: flex;
    justify-content: space-between;

    & > div {
      border-radius: 16px;
      background: #fff;
      height: 458px;
      flex-shrink: 0;
    }
    .graph1 {
      width: calc(50% - 8px);
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .top_selling {
      width: calc(50% - 8px);
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 992px) {
      flex-direction: column;
      margin-top: 20px;
    }
  }

  .recent_orders {
    width: 100%;
    height: 642px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #fff;
  }
  .gradienticon {
    align-items: center;
    display: flex;
    justify-content: center;
    background: linear-gradient(253deg, #ff9103 29.53%, #f60100 103.89%);
  }
}
</style>
      